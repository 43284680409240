import * as React from 'react'
import Layout from '../../components/layout'
import NewBespokeHero from '../../components/hero/new-bespoke-hero'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import Lightbox from 'yet-another-react-lightbox'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import 'yet-another-react-lightbox/plugins/captions.css'
import '@fontsource/montserrat'
import {
  buttonsContainer
} from'../../lib/buttons.module.css'
import {
  containerLightBox,
  galleryContainer,
  gallery
} from '../../lib/whatsnew.module.css'

const activeStyles = {
  color: 'brown'
}

const acrylicbendSlides = [
  { src: '/bespoke/originals/acrylicbend.jpg', title: 'Acrylic Bend Satin Nickel' },
];
const doublefrenchpoleSlides = [
  { src: '/bespoke/originals/doublefrenchpole.jpg', title: 'Double French Pole Satin Nickel' },
];
const electricpoleSlides = [
  { src: '/bespoke/originals/electricpole.jpg', title: 'Electric Pole Satin Brass and Burned Oak' },
];
const frenchpoleSlides = [
  { src: '/bespoke/originals/frenchpole1.jpg', title: 'French Pole' },
  { src: '/bespoke/originals/frenchpole2.jpg', title: 'French Pole' },
  { src: '/bespoke/originals/frenchpole3.jpg', title: 'French Pole' },
];
const meteredreturnSlides = [
  { src: '/bespoke/originals/meteredreturn1.jpg', title: 'Metered Return' },
  { src: '/bespoke/originals/meteredreturn2.jpg', title: 'Metered Return' },
];
const trackedbaypoleSlides = [
  { src: '/bespoke/originals/trackedbaypole1.jpg', title: 'Tracked Bay Pole' },
  { src: '/bespoke/originals/trackedbaypole2.jpg', title: 'Tracked Bay Pole' },
  { src: '/bespoke/originals/trackedbaypole3.jpg', title: 'Tracked Bay Pole' },
  { src: '/bespoke/originals/trackedbaypole4.jpg', title: 'Tracked Bay Pole' },
  { src: '/bespoke/originals/trackedbaypole5.jpg', title: 'Tracked Bay Pole' },
];
const trackedpolesampleSlides = [
  { src: '/bespoke/originals/trackedpolesample.jpg', title: 'Tracked Pole Sample' },
];

const NewBespoke = () => {  
const [openAcrylicBend, setOpenAcrylicBend] = React.useState(false);
const [openDoubleFrenchPole, setOpenDoubleFrenchPole] = React.useState(false);
const [openElectricPole, setOpenElectricPole] = React.useState(false);
const [openFrenchPole, setOpenFrenchPole] = React.useState(false);
const [openMeteredReturn, setOpenMeteredReturn] = React.useState(false);
const [openTrackedBayPole, setOpenTrackedBayPole] = React.useState(false);
const [openTrackedPoleSample, setOpenTrackedPoleSample] = React.useState(false);
  return (
    <Layout pageTitle="New Bespoke">
      <NewBespokeHero />
      <div className={buttonsContainer} style={{width:"800px", margin:"auto"}}>
        <div><Link to="/whatsnew/finials" activeStyle={activeStyles} partiallyActive={true}>Finials</Link></div>
        <div><Link to="/whatsnew/newbespoke" activeStyle={activeStyles} partiallyActive={true}>New Bespoke</Link></div>
      </div>
    <div className={galleryContainer}>
        <div className={gallery}>
          <div>
            <button type="button" onClick={() => setOpenAcrylicBend(true)}>
              <StaticImage
                layout="CONSTRAINED"
                width="640"
                alt="Bespoke"
                src={"../../images/whatsnew/bespokethumbs/acrylicbend.jpg"}
                formats={["webp", "avif"]}
                placeholder="tracedSVG"
                quality="100"
              />
            </button>
            <h3>Acrylic Bend</h3>
            <Lightbox
                className={containerLightBox}
                plugins={[Thumbnails, Captions, Fullscreen]}
                slides={acrylicbendSlides}
                open={openAcrylicBend}
                close={() => setOpenAcrylicBend(false)}
            />
          </div>
          <div>
            <button type="button" onClick={() => setOpenDoubleFrenchPole(true)}>
              <StaticImage
                layout="CONSTRAINED"
                width="640"
                alt="Bespoke"
                src={"../../images/whatsnew/bespokethumbs/doublefrenchpole.jpg"}
                formats={["webp", "avif"]}
                placeholder="tracedSVG"
                quality="100"
              />
            </button>
            <h3>Double French Pole</h3>
            <Lightbox
                className={containerLightBox}
                plugins={[Thumbnails, Captions, Fullscreen]}
                slides={doublefrenchpoleSlides}
                open={openDoubleFrenchPole}
                close={() => setOpenDoubleFrenchPole(false)}
            />
          </div>
          <div>
            <button type="button" onClick={() => setOpenElectricPole(true)}>
              <StaticImage
                layout="CONSTRAINED"
                width="640"
                alt="Bespoke"
                src={"../../images/whatsnew/bespokethumbs/electricpole.jpg"}
                formats={["webp", "avif"]}
                placeholder="tracedSVG"
                quality="100"
              />
            </button>
            <h3>Electric Pole</h3>
            <Lightbox
                className={containerLightBox}
                plugins={[Thumbnails, Captions, Fullscreen]}
                slides={electricpoleSlides}
                open={openElectricPole}
                close={() => setOpenElectricPole(false)}
            />
          </div>
          <div>
            <button type="button" onClick={() => setOpenFrenchPole(true)}>
              <StaticImage
                layout="CONSTRAINED"
                width="640"
                alt="Bespoke"
                src={"../../images/whatsnew/bespokethumbs/frenchpole.jpg"}
                formats={["webp", "avif"]}
                placeholder="tracedSVG"
                quality="100"
              />
            </button>
            <h3>French Pole</h3>
            <Lightbox
                className={containerLightBox}
                plugins={[Thumbnails, Captions, Fullscreen]}
                slides={frenchpoleSlides}
                open={openFrenchPole}
                close={() => setOpenFrenchPole(false)}
            />
          </div>
          <div>
            <button type="button" onClick={() => setOpenMeteredReturn(true)}>
              <StaticImage
                layout="CONSTRAINED"
                width="640"
                alt="Bespoke"
                src={"../../images/whatsnew/bespokethumbs/meteredreturn.jpg"}
                formats={["webp", "avif"]}
                placeholder="tracedSVG"
                quality="100"
              />
            </button>
            <h3>Metered Return</h3>
            <Lightbox
                className={containerLightBox}
                plugins={[Thumbnails, Captions, Fullscreen]}
                slides={meteredreturnSlides}
                open={openMeteredReturn}
                close={() => setOpenMeteredReturn(false)}
            />
          </div>
          <div>
            <button type="button" onClick={() => setOpenTrackedBayPole(true)}>
              <StaticImage
                layout="CONSTRAINED"
                width="640"
                alt="Bespoke"
                src={"../../images/whatsnew/bespokethumbs/trackedbaypole.jpg"}
                formats={["webp", "avif"]}
                placeholder="tracedSVG"
                quality="100"
              />
            </button>
            <h3>Tracked Bay Pole</h3>
            <Lightbox
                className={containerLightBox}
                plugins={[Thumbnails, Captions, Fullscreen]}
                slides={trackedbaypoleSlides}
                open={openTrackedBayPole}
                close={() => setOpenTrackedBayPole(false)}
            />
          </div>
          <div>
            <button type="button" onClick={() => setOpenTrackedBayPole(true)}>
              <StaticImage
                layout="CONSTRAINED"
                width="640"
                alt="Bespoke"
                src={"../../images/whatsnew/bespokethumbs/trackedpolesample.jpg"}
                formats={["webp", "avif"]}
                placeholder="tracedSVG"
                quality="100"
              />
            </button>
            <h3>Tracked Pole Sample</h3>
            <Lightbox
                className={containerLightBox}
                plugins={[Thumbnails, Captions, Fullscreen]}
                slides={trackedpolesampleSlides}
                open={openTrackedPoleSample}
                close={() => setOpenTrackedPoleSample(false)}
            />
          </div>
        </div>
    </div>
    </Layout>
  )
}

export default NewBespoke