import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import "@fontsource/montserrat"
import {
    heroWNContainer
} from'./lib/hero.module.css'


const NewBespokeHero = () => {
    return (
        <div className={heroWNContainer}>
            <StaticImage
                layout="CONSTRAINED"
                alt="NewBespoke"
                src={"../../images/whatsnew/bespoke.jpg"}
                formats={["webp", "avif"]}
                placeholder="tracedSVG"
                quality="100"
            />
        </div>
    )
  }
  
export default NewBespokeHero